import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/WithTracking/Button';
import { LOHPPageSections, NavTrackActions } from '@/constants/analytics';
import { LINK_TYPE, NON_C2N_ROUTES } from '@/constants/global';
import { useFormatLink } from '@/hooks/useFormatLink';
import { PageSection } from '@/lib/analytics/react-data-tracking';
import { NAMESPACE_LOHP } from '@/translations/namespaces';

const styles = {
  storyTellingGrid: ({ breakpoints, spacing }: Theme) => ({
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: spacing(35),
    gridGap: spacing(1.25),
    alignItems: 'center',
    margin: spacing(0, 0, 9, 0),
    [breakpoints.up('xl')]: {
      gridTemplateRows: spacing(40),
    },
    [breakpoints.between('sm', 'md')]: {
      gridTemplateRows: spacing(40),
      margin: spacing(0, 0, 6, 0),
    },
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: spacing(20, 37.5),
      gridGap: spacing(1.25),
      margin: spacing(0, 0, 0, 0),
      gridTemplateAreas: `
      'a'
      'b'
      `,
    },
  }),
  storyTellingCardText: ({ breakpoints }: Theme) => ({
    textAlign: 'left',
    [breakpoints.down('sm')]: {
      gridArea: 'b',
    },
  }),
  storyTellingCardTextRight: ({ breakpoints, spacing }: Theme) => ({
    padding: spacing(0, 0, 0, 5),
    [breakpoints.down('sm')]: {
      gridArea: 'b',
      padding: spacing(0, 0, 0, 0),
    },
  }),
  storyTellingCardImage: ({ breakpoints }: Theme) => ({
    objectFit: 'cover' as const,
    justifySelf: 'center',
    alignSelf: 'center',
    [breakpoints.up('xl')]: {
      overflow: 'hidden',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    [breakpoints.down('sm')]: {
      gridArea: 'a',
      overflow: 'hidden',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
  buttonTypography: ({ breakpoints, spacing, tokens: { font } }: Theme) => ({
    fontSize: spacing(2.5),
    fontWeight: font.weight.normal,
    [breakpoints.between('sm', 'md')]: {
      fontSize: spacing(2),
    },
  }),
};

const StoryTellingButton = ({
  href,
  trackAction,
  newTab = false,
  children,
}: {
  href: string;
  trackAction: string;
  newTab?: boolean;
  children: string;
}) => (
  <Button
    variant="secondary"
    sx={{
      minWidth: '50%',
      minHeight: '100%',
      padding: ({ spacing }: Theme) => spacing(0, 1, 0, 1),
      '&:hover': {
        color: ({ palette }: Theme) => palette.text.inverse,
        backgroundColor: ({ palette }) => palette.button.pressed,
      },
    }}
    sxTypography={styles.buttonTypography}
    href={href}
    trackAction={trackAction}
    target={newTab ? '_blank' : undefined}
  >
    {children}
  </Button>
);

const FirstStoryTellingGrid = () => {
  const { locale } = useRouter();
  const { t } = useTranslation(NAMESPACE_LOHP);

  const { spacing } = useTheme();

  const globalEarningsUrl = useFormatLink({
    href:
      locale === 'en'
        ? `https://www.shutterstock.com/blog/1-billion-contributor-earnings`
        : `https://www.shutterstock.com/blog/trends/${locale}/1-billion-contributor-earnings`,
  });
  const exploreToolsUrl = useFormatLink({
    href: '/explore/contributor-editor-go-to',
    linkType: LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC,
  });

  return (
    <PageSection value={LOHPPageSections.firstStorytellingSection}>
      <Box data-testid="first-storytelling-container">
        <Box sx={styles.storyTellingGrid}>
          <Box sx={styles.storyTellingCardText}>
            <Typography component="h3" variant="h4" sx={{ paddingBottom: spacing(1) }}>
              {t('contributor_lohp_stories_payout_title_new')}
            </Typography>
            <Typography variant="body1" sx={{ paddingBottom: spacing(2) }}>
              {t('contributor_lohp_stories_payout_text')}
            </Typography>
            <StoryTellingButton href={NON_C2N_ROUTES.SIGN_UP} trackAction={NavTrackActions.signUpLink}>
              {t('contributor_lohp_stories_payout_link_text')}
            </StoryTellingButton>
          </Box>
          <Box
            sx={styles.storyTellingCardImage}
            component="img"
            src="https://submit2.shutterstock.com/public/img/lohp/paid_out-734550afc2.jpg"
            width="100%"
            height="100%"
            alt="A phone and a large camera with red accents on a white background. The phone displays the back of a girls head as she looks out onto wild landscape."
            loading="lazy"
          />
        </Box>
        <Box sx={styles.storyTellingGrid}>
          <Box
            sx={styles.storyTellingCardImage}
            component="img"
            src="https://submit2.shutterstock.com/public/img/lohp/community-d413498d09.jpg"
            width="100%"
            height="100%"
            alt="Five people jumping happily in the air. Some of them have thrown their arms up and are smiling. The background is brick wall with boarded up windows."
            loading="lazy"
          />
          <Box sx={styles.storyTellingCardTextRight}>
            <Typography component="h3" variant="h4" sx={{ paddingBottom: spacing(1) }}>
              {t('contributor_lohp_stories_community_title')}
            </Typography>
            <Typography variant="body1" sx={{ paddingBottom: spacing(2) }}>
              {t('contributor_lohp_stories_community_text')}
            </Typography>
            <StoryTellingButton href={globalEarningsUrl} trackAction={NavTrackActions.globalEarningsLink} newTab>
              {t('contributor_lohp_stories_community_link_text')}
            </StoryTellingButton>
          </Box>
        </Box>
        <Box sx={styles.storyTellingGrid}>
          <Box sx={styles.storyTellingCardText}>
            <Typography component="h3" variant="h4" sx={{ paddingBottom: spacing(1) }}>
              {t('contributor_lohp_stories_tools_title')}
            </Typography>
            <Typography variant="body1" sx={{ paddingBottom: spacing(2) }}>
              {t('contributor_lohp_stories_tools_text')}
            </Typography>
            <StoryTellingButton href={exploreToolsUrl} trackAction={NavTrackActions.exploreToolsLink} newTab>
              {t('contributor_lohp_stories_tools_link_text')}
            </StoryTellingButton>
          </Box>
          <Box
            sx={styles.storyTellingCardImage}
            component="img"
            src="https://submit2.shutterstock.com/public/img/lohp/tools-d4ef7db2e7.jpg"
            width="100%"
            height="100%"
            alt="The Contributor portfolio experience that allows users to edit and submit assets to Shutterstock"
            loading="lazy"
          />
        </Box>
      </Box>
    </PageSection>
  );
};

const SecondStoryTellingGrid = () => {
  const { t } = useTranslation(NAMESPACE_LOHP);
  const { spacing } = useTheme();

  const contributorAppUrl = useFormatLink({
    href: '/explore/contributor-mobile-app',
    linkType: LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC,
  });

  return (
    <PageSection value={LOHPPageSections.secondStorytellingSection}>
      <Box data-testid="second-storytelling-container">
        <Box sx={styles.storyTellingGrid}>
          <Box
            sx={styles.storyTellingCardImage}
            component="img"
            src="https://submit2.shutterstock.com/public/img/lohp/marketplace-3d4d802b60.jpg"
            width="100%"
            height="100%"
            alt="A photographer adjusting the settings on a camera while looking through the viewfinder. A tranquil rocky waterfall appears on the LCD screen."
            loading="lazy"
          />
          <Box sx={styles.storyTellingCardTextRight}>
            <Typography component="h3" variant="h4" sx={{ paddingBottom: spacing(1) }}>
              {t('contributor_lohp_stories_examples_title')}
            </Typography>
            <Typography variant="body1" sx={{ paddingBottom: spacing(2) }}>
              {t('contributor_lohp_stories_examples_text')}
            </Typography>
            <StoryTellingButton href={NON_C2N_ROUTES.SIGN_UP} trackAction={NavTrackActions.signUpLink}>
              {t('contributor_lohp_stories_examples_link_text')}
            </StoryTellingButton>
          </Box>
        </Box>
        <Box sx={styles.storyTellingGrid}>
          <Box sx={styles.storyTellingCardText}>
            <Typography component="h3" variant="h4" sx={{ paddingBottom: spacing(1) }}>
              {t('contributor_lohp_stories_app_title')}
            </Typography>
            <Typography variant="body1" sx={{ paddingBottom: spacing(2) }}>
              {t('contributor_lohp_stories_app_text')}
            </Typography>
            <StoryTellingButton href={contributorAppUrl} trackAction={NavTrackActions.contributorAppLink} newTab>
              {t('contributor_app')}
            </StoryTellingButton>
          </Box>
          <Box
            component="img"
            src="https://submit2.shutterstock.com/public/img/lohp/app-d019150d52.jpg"
            width="100%"
            height="100%"
            alt="Two phones showing the Contributor App and a camera with a lens cap on in the top right corner."
            sx={styles.storyTellingCardImage}
            loading="lazy"
          />
        </Box>
      </Box>
    </PageSection>
  );
};

export { FirstStoryTellingGrid, SecondStoryTellingGrid };
